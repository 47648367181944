<template>
  <!-- BEGIN: Modal Content -->
  <div id="meals-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base flex items-center gap-2">
            <div class="text-theme-31">
              <app-i18n code="chefs.sectionMeals" class="text-black"></app-i18n>
            </div>
          </h2>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <!-- <label class="form-label text-xl mb-4">{{
              i18n('chefs.meals')
            }}</label> -->
            <div
              v-for="meal in section?.meals"
              :key="meal"
              class="flex gap-2 text-base items-center"
            >
              <Icon icon="uil:food" />
              {{
                isRTL
                  ? meal.name.ar || meal.name.en
                  : meal.name.en || meal.name.ar
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    section: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const hideModal = () => {
      cash('#meals-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
