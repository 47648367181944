<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('chefs.meals') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex sm:mt-0 gap-2 sm:flex-nowrap flex-wrap">
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onDownloadPdf="onDownloadPdf()"
            :printPermission="hasPermissionToPrint"
            :exportPermission="hasPermissionToExport"
            :canExportAll="false"
          ></app-action-toolbar>
        </div>
      </div>
      <div class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table
            ref="tableRef"
            class="table table-report sm:mt-2"
            id="tablePrint"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : col.name === 'idCard'
                      ? 'ignore-4'
                      : col.name === 'taxNumber'
                      ? 'ignore-5'
                      : col.name === 'freelanceCertificate'
                      ? 'ignore-6'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                  :class="{
                    'cursor-pointer': col.sortable,
                    hidden: col.field === 'mealsName'
                  }"
                >
                  <span>{{ i18n(col.label) }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr
                v-for="(row, index) in rows"
                :key="index"
                class="intro-x"
                :class="{ removedRow: row.isRemoved }"
              >
                <td class="w-40">
                  <div
                    class="text-gray-600 text-xs whitespace-nowrap mt-0.5 text-center"
                  >
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row.kitchen, 'kitchenName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'code') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ row.city ? (isRTL ? row.city.ar : row.city.en) : 'ـــ' }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'sectionsCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'mealsCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap flex justify-center px-6 p-1 gap-4"
                    v-for="section in row.sections"
                    :key="section.id"
                  >
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#meals-modal"
                      @click="selectedSection = section"
                      v-if="section?.meals?.length"
                      class="flex justify-center w-full break-words whitespace-pre-wrap text-primary-2"
                      :class="isRTL ? 'text-right' : ' text-left'"
                      >{{ presenterIfLocalization(section, 'name') }}</a
                    >
                    <span
                      v-else
                      class="flex justify-center w-full break-words whitespace-pre-wrap"
                      >{{ presenterIfLocalization(section, 'name') }}</span
                    >
                  </div>
                </td>
                <td class="text-center hidden">
                  <div
                    class="font-medium whitespace-nowrap px-6 p-1"
                    v-for="section in row.sections"
                    :key="section.id"
                  >
                    <span class="text-left">{{
                      section.meals?.length
                        ? section.meals
                            ?.map(meal =>
                              isRTL
                                ? meal.name.ar || meal.name.en
                                : meal.name.en || meal.name.ar
                            )
                            .join(', ')
                        : 'ــــــــــــ'
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.chef')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :hasNext="hasNext"
          :hasPrev="hasPrev"
          :loading="loading"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
          @page-change="goToPage"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Modal -->
    <ActivateModal item="chef" />
    <DisableModal item="chef" />
    <mealsModal :section="selectedSection" @close="selectedSection = null" />
    <!-- END: Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import { ChefPermissions } from './chef-permissions'
import mealsModal from '@/components/modals/meals-modal.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  components: {
    ActivateModal,
    DisableModal,
    mealsModal
  },
  data() {
    return {
      // data important for Mixins
      fetchOptions: { type: 'chef' },
      tableRef: null,
      exportTableName: 'meals',
      exportResourceName: 'chef',
      excludedCols: [],
      ignoreElements: [
        'ignore-1',
        'ignore-2',
        'ignore-3',
        'ignore-4',
        'ignore-5',
        'ignore-6',
        'ignore-7',
        'ignore-8',
        'ignore-9',
        'ignore-10',
        'ignore-11',
        'ignore-12'
      ],
      // component Data
      selected: [],
      selectAll: false,
      selectedId: null,
      selectedSection: null,
      columns: [
        {
          name: 'fullName',
          field: 'fullName',
          label: 'iam.fields.fullName',
          align: 'center'
        },
        {
          name: 'kitchenName',
          field: 'kitchenName',
          label: 'iam.chefDetails.kitchen.title',
          align: 'center'
        },
        {
          name: 'chefCode',
          field: 'chefCode',
          label: 'iam.fields.chefCode',
          align: 'center'
        },
        {
          name: 'chefCity',
          field: 'chefCity',
          label: 'iam.fields.city',
          align: 'center'
        },
        {
          name: 'sectionsCount',
          field: 'sectionsCount',
          label: 'iam.fields.sectionsCount',
          align: 'center'
        },
        {
          name: 'mealsCount',
          field: 'mealsCount',
          label: 'iam.fields.mealsCount',
          align: 'center'
        },
        {
          name: 'sectionsName',
          field: 'sectionsName',
          label: 'iam.fields.sectionsName',
          align: 'center'
        },
        {
          name: 'mealsName',
          field: 'mealsName',
          label: 'iam.fields.mealsName',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'user/list/rows',
      hasNext: 'user/list/hasNext',
      hasPrev: 'user/list/hasPrev',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading',
      destroyLoading: 'user/destroy/loading',
      saveLoading: 'user/form/saveLoading'
    }),
    hasPermissionToEdit() {
      return new ChefPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new ChefPermissions(this.currentUser).destroy
    },
    hasPermissionToChangeStatus() {
      return new ChefPermissions(this.currentUser).changeStatus
    },
    hasPermissionToPrint() {
      return new ChefPermissions(this.currentUser).print
    },
    hasPermissionToExport() {
      return new ChefPermissions(this.currentUser).export
    },
    hasPermissionToSendNotification() {
      return new ChefPermissions(this.currentUser).sendNotification
    },
    loading() {
      return this.listLoading || this.destroyLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        {
          label: this.i18n('chefs.enumerators.status.enabled'),
          value: 'enabled'
        },
        {
          label: this.i18n('chefs.enumerators.status.rejected'),
          value: 'rejected'
        },
        {
          label: this.i18n('chefs.enumerators.status.disabled'),
          value: 'disabled'
        },
        {
          label: this.i18n('chefs.enumerators.status.pendingupdate'),
          value: 'pendingupdate'
        },
        {
          label: this.i18n('chefs.enumerators.status.pendingAdd'),
          value: 'pendingAdd'
        }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.fields.chefCode'),
          value: 'code',
          type: 'text'
        },
        {
          label: this.i18n('chefs.filter.fullName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('iam.chefDetails.kitchen.title'),
          value: 'kitchen.kitchenName',
          type: 'text',
          localize: true
        },
        {
          label: this.i18n('iam.fields.city'),
          value: 'city',
          type: 'text',
          localize: true
        }
        // {
        //   label: this.i18n('chefs.filter.phoneNumber'),
        //   value: 'phoneNumber',
        //   type: 'text'
        // },
        // {
        //   label: this.i18n('iam.fields.joinDate'),
        //   value: 'createdAt',
        //   type: 'date-range'
        // }
      ]
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'user/view/doToggleUserAccountStatus',
      doEnableAll: 'user/list/doEnableAllSelected',
      doDisableAll: 'user/list/doDisableAllSelected',
      doDestroyAll: 'user/destroy/doDestroyAll'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    }
  }
}
</script>
<style scoped lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

.embed-pdf-scroll::-webkit-scrollbar {
  width: 1px !important;
  height: 1px !important;
  // color: red !important;
  // background: red;
  border-radius: 50px;
}
.embed-pdf-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
.embed-pdf-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: black !important;
  // outline: 5px solid greenyellow !important;
}
</style>
